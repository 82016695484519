<template>
  <nav class="navbar">
    <ul class="navbar-list">
      <li class="navbar-icon">
        <img
          class="navbar-icon__img"
          src="/images/onclusive-emblem-navigation.svg"
          alt="Onclusive Logo"
        />
      </li>
      <ClientOnly>
        <li v-for="opt in activeOptions" :key="opt.id">
          <HeaderNavBarOption
            v-if="
              opt.id === flagValue?.routeId
                ? opt.display && flagValue?.isActive
                : opt.display
            "
            :id="opt.id"
            :display-text="opt.translationKey"
            :path="opt.path"
            :data-intercom-target="opt.dataTarget"
            :selected-link="checkSelectionStatus(opt)"
            :active-group="activeGroup"
            @click="onClick(opt)"
          />
        </li>
      </ClientOnly>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { computed, onMounted, watchEffect, Ref, ref } from "vue";
import { useNavigationStore } from "@/store/navigation";
import NavBarOption from "~/types/NavBarOptionType";
import { useLaunchDarkly } from "~/composables/useLaunchDarkly";
import { useLaunchDarklyStore } from "~/store/launchDarkly";
import { useUserStore } from "~/store/user";
import { useOrgStore } from "~/store/org";
import { defaultFlag } from "~/constants/flags";
import Flag from "~/types/Flag";

const { updateFlagValue } = useLaunchDarkly();
const launchDarklyStore = useLaunchDarklyStore();
const navigationStore = useNavigationStore();
const userStore = useUserStore();
const orgStore = useOrgStore();

const searchFlagName = "authorsAndPublicationsTab";
const flagValue: Ref<Flag> = ref(defaultFlag);

onMounted(() => {
  const props = {
    kind: "user",
    key: userStore.getUser?.id,
    email: userStore.getUser?.email,
    user_name: userStore.getUser?.name,
    is_impersonated: userStore.getUser?.is_impersonated,
    org_id: orgStore.getOrg?.id,
    org_name: orgStore.getOrg?.attributes.name,
    org_plan: orgStore.getOrg?.attributes.plan_name,
    internal_account: orgStore.getOrg?.attributes.internal_account,
    digital_licences: orgStore.getOrg?.attributes.digital_licenses,
    print_licensces: orgStore.getOrg?.attributes.print_licenses,
    license_countries: orgStore.getOrg?.attributes.license_countries,
    license_region: orgStore.getOrg?.attributes.license_region,
  };
  updateFlagValue(props, searchFlagName);
});

watchEffect(() => {
  flagValue.value =
    launchDarklyStore.getFlagByName(searchFlagName) ?? defaultFlag;
});

const activeOptions = computed(() => {
  return navigationStore.activeNavBarOptions;
});

const activeGroup = computed(() => {
  return navigationStore.activeGroup;
});

const onClick = (opt: NavBarOption) => {
  navigationStore.activeNavBarOption = opt.id;
};

const checkSelectionStatus = (opt: NavBarOption) => {
  return navigationStore.activeNavBarOption === opt.id;
};
</script>

<style scoped>
.navbar {
  @apply fixed top-0 z-20 w-full;
}
.navbar-list {
  @apply flex h-20 bg-white-200 border-solid;
}
.navbar-icon {
  @apply w-24 h-20 bg-white-200;
}
.navbar-icon__img {
  @apply object-cover w-24 h-20;
}
</style>
